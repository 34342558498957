import PocketBase from "pocketbase";

let pb;

if (process.env.NODE_ENV === "development") {
  pb = new PocketBase(process.env.REACT_APP_PB_URL);
} else {
  pb = new PocketBase();
}

pb.authStore.clear();

const authData = await pb.admins.authWithPassword(
  `${process.env.REACT_APP_ADMIN_EMAIL}`,
  `${process.env.REACT_APP_ADMIN_PASSWORD}`
);

pb.autoCancellation(false);

export default pb;
