import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "views/Home/Home.js";
import React, { useState, useEffect, lazy, Suspense } from "react";
import pb from "lib/pocketbase.js";

import Icon from "components/Logo";
import Disable from "components/Disable/Disable.js";
import ImportForm from "components/ImportForm/ImportForm.js";

function App() {
  const [isActivated, setActivated] = useState(false);
  const [importRoute, setImportRoute] = useState(null);

  const getRouteImport = async () => {
    const record = await pb
      .collection("routes")
      .getFirstListItem('name="import"');
    setImportRoute(record);
  };

  useEffect(() => {
    pb.collection("appconf")
      .getFirstListItem('name="activated"')
      .then((res) => setActivated(res.status));

    getRouteImport();
  }, []);

  return (
    <>
      <>
        <header className="app_header">
          <Icon width={200} />
        </header>

        <BrowserRouter>
          <Routes>
            <Route path="/" element={isActivated ? <Home /> : <Disable />} />
            {importRoute && importRoute.active ? (
              <Route path="/import" element={<ImportForm />} />
            ) : null}
          </Routes>
        </BrowserRouter>
      </>

      <main className="blocked">
        <Icon width={400} />
        <p>This website is only available to desktop screens.</p>
      </main>
    </>
  );
}

export default App;
